import React from "react";
import {graphql} from "gatsby";
import Layout from "../components/Layout";
import Metas from "../components/Metas";
import Title from "../components/Title";
import MenuLink from "../components/MenuLink";
import CustomBreadcrumb from "../components/CustomBreadcrumb";

const Portal = ({pageContext, data}) => {
    const {
        breadcrumb: {crumbs},
    } = pageContext

    return (
        <Layout>
            <Metas title={pageContext.title}/>
            <section className="section page-breadcrumb is-small has-background-light">
                <div className="container">
                    <CustomBreadcrumb crumbs={crumbs}/>
                </div>
            </section>
            <section className="section page-content">
                <div className="container is-max-desktop">
                    <Title title={pageContext.title} typesense={false}/>
                    <div className="columns is-multiline">
                        {data.childrens.nodes.map((item, index) => (
                            <div key={index} className="column is-4 is-3-desktop portal-link">
                                <MenuLink internalId={item.link.uri} className="has-background-light" {...item.link.options.attributes}>
                                    {item.title}
                                    <span className="icon icon-medium">
                                        <i className="li li-plus"></i>
                                    </span>
                                </MenuLink>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Portal

export const query = graphql`
    query portalPageElements ($parentId: String!) {
        childrens: allMenuLinkContentMenuLinkContent(
            filter: {
                enabled: {eq: true}, 
                menu_name: {eq: "main"}, 
                drupal_parent_menu_item: {
                    eq: $parentId
                }
            }
            sort: {fields: weight, order: ASC}
        ) {
            nodes {
                title
                drupal_parent_menu_item
                drupal_id
                link {
                    uri
                    options {
                        attributes {
                            data_has_icon
                            data_icon
                            data_icon_size
                            data_text_invisible
                            target
                        }
                    }
                }
            }
        }
    }
`
